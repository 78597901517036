import { TextConfig } from "./TextConfig";

export class InsurancelineText extends TextConfig {
    constructor() {
        super()
        this.brand = 'Insuranceline';
        this.title = 'IL';
        this.phoneNumber = '1800 002 401';
        this.privacyPolicy = 'https://www.insuranceline.com.au/Privacy-Policy';
        this.terms = 'Insuranceline';
        this.pleaseNote = '1800 002 401 (Mon-Fri 9am-5pm AET)';
        this.footer = 'Insuranceline';
        this.hasSuperPolicy = false;
        this.aboutUs = 'https://www.insuranceline.com.au/about-us';
        this.security = 'https://www.insuranceline.com.au/security';
        this.complaints = 'https://www.insuranceline.com.au/contact-us/complaints';
        this.disclaimer = 'https://www.insuranceline.com.au/site-terms';
        this.contactTime = 'Mon-Fri 9am-5pm AET';
    };
}