import { TextConfig } from "./TextConfig";

export class AAMIText extends TextConfig {
    constructor() {
        super()
        this.brand = 'AAMI Life Limited';
        this.title = 'AAMI';
        this.phoneNumber = '1300 420 233';
        this.privacyPolicy = 'https://www.aami.com.au/aami/documents/aami/privacy/aami-privacy-statement-life-insurance.pdf';
        this.terms = 'AAMI Life Limited';
        // this.pleaseNote = '1300 420 233 (Mon-Fri 8am-7pm AET)';
        this.footer = 'TAL Life Limited ABN 70 050 109 450';
        this.hasSuperPolicy = false;
        this.aboutUs = 'https://www.aami.com.au/about-aami.html';
        this.security = 'https://www.aami.com.au/privacy/cookie-and-data-policy.html';
        this.complaints = 'mailto:customerservice@aamilifeinsurance.com.au';
        this.disclaimer = 'https://www.aami.com.au/contact/code-of-practice.html#licop';
        // this.contactTime = 'Mon-Fri 8am-7pm AET';
        this.disclaimerBrand = 'AAMI Life Limited';
        this.expiryDateMarginTop = 2.1;
        this.policyNotFound = 'The information you have entered is incorrect, please try again. For assistance, contact our support team on the number listed on your policy document.';
    };
}



// Text for AAMI off sale (SourceSystemCode = LFH)
export class AAMIOffSaleText extends AAMIText {
    constructor() {
        super()   
        this.phoneNumber = "13 22 44";
        this.pleaseNote = '13 22 44, 8am to 6pm AEST Monday to Friday';
        this.contactTime = '8am-6pm AEST Monday to Friday';
        this.contactTimeFormat = ', 8am to 6pm AEST Monday to Friday';
       };
}

// Text for AAMI on sale ( SourceSystemCode = ETH)
export class AAMIOnSaleText extends AAMIText {
    constructor() {
        super()
        this.phoneNumber = "1300 420 233";
        this.pleaseNote = '1300 420 233, 8am - 7pm, Monday to Friday, AEST';
        this.contactTime = '8am - 7pm, Monday to Friday, AEST';
        this.contactTimeFormat = ', 8am - 7pm, Monday to Friday, AEST';
        
      };
}