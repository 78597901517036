import React, { useContext, useState, useEffect } from "react";
import { isFunction } from '@tal-gel/core';
import { getGelTokens } from '@tal-gel/theming';
import {
    GelScreenDetectorContext, GelRowLayout, GelForm,
    GelHeading6, GelParagraph, onNextStep, GelLabel,
    GelFormField, GelTextInput, GelBoxLayout, GelCaption,
    GelButton, useGelFormData, GelDateInput, GelSpinner, useGetReCaptchaV3Token, ReCaptchaV3Provider
} from '@tal-gel/components';

import { whiteBackgroundStyle, Brands } from '../Shared';
import CustomContainer from '../Shared/CustomContainer';
import { unAuthenticateQuery } from '../../services/unAuthenticatedPaymentService';
import { getPolicy } from '../../services/unAuthenticPaymentQueries/getPolicy';
import helpers from '../Shared/helpers';
import envConfig from '../../env.config';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react';
import sendAdobeTag from '../../services/sendAdobeTag';

const PolicyNumber = React.memo(({ width, isAdobeScriptLoaded }) => {
    const PolicyStatus = {
        AUTHLOCKED: "Locked",
        AUTHUNLOCKED: "Unlocked"
    };
    const { formData, onFormDataChange, onNextStep, multiStepFormData, onFormFieldChange } = useGelFormData(
        {
            policyNumber: "",
            dOB: "",
            showDateOB: false,
            amount: "",
            overdueAmount: "",
            isOverdue: "",
            isValidOverdue: "",
            merchantId: "",
            policyNo: "",
            businessArea:""
        },
        "policyNumber"
    );

    const [loading, setLoading] = useState();
    const [showDob, setShowDob] = useState(formData.showDateOB);
    const [policyNotFound, setPolicyNotFound] = useState();
    const [policyStatus, setPolicyStatus] = useState(PolicyStatus.AUTHUNLOCKED);
    const [requestFailed, setRequestFailed] = useState();
    const [responseError, setResponseError] = useState();
    const getReCaptchaToken = useGetReCaptchaV3Token('quickPayment');
    const [isInternal, setIsInternal] = useState(helpers.IsInternalUrl());
    const [isDuplicatePolicy, setIsDuplicatePolicy] = useState(false);
    const currentBrand = helpers.GetCurrentBrand();
    const { instance, inProgress, accounts } = useMsal();
    const [displayText] = useState(helpers.SetCurrentBrandText(currentBrand));    
    width = helpers.GetComponentScreenWidth();

    const LOCALSTORAGE_Policy_KEY = "Policy-number";

    // Send data to marketing team
    useEffect(() => {
        if(isAdobeScriptLoaded)
          sendAdobeTag({ brand: isInternal ? '' : currentBrand, pageEventName:"Form Start", formStep:"Payment Home"});        
    }, [isAdobeScriptLoaded]);

    useEffect(() => {
        if(localStorage.hasOwnProperty(LOCALSTORAGE_Policy_KEY)) {
            formData.policyNumber = localStorage.getItem(LOCALSTORAGE_Policy_KEY);
            localStorage.removeItem(LOCALSTORAGE_Policy_KEY);
        }
    }, []);

    const beforeSubmitCleanData = () => {
        setPolicyNotFound(false);
        setPolicyStatus(PolicyStatus.AUTHUNLOCKED);
        if(isInternal && !showDob)
            formData.dOB = ''
        formData.amount = '';
        formData.isOverdue = '';
        formData.overdueAmount = '';
        //set merchatid and policyNo to pass to Bpoint service call
        formData.merchantId = '';
        formData.brandCode = '';
        formData.brandName = '';
        formData.sourceSystemCode = '';
        formData.isValidOverdue = '';
        formData.businessArea = '';
    }

    const tokenRequest = {
        scopes: [`api://${envConfig.AAD_CLIENTID}/Tasks.Read`], 
        forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
    }

    const getBearToken = async () => {
        try {
            let tokenResponse = await instance.acquireTokenSilent(tokenRequest);            
            return tokenResponse.accessToken;
        } catch(error) {
            console.log(error);
            localStorage.setItem(LOCALSTORAGE_Policy_KEY, formData.policyNumber)
            await instance.acquireTokenRedirect(tokenRequest).catch((error) => { console.log(error); });            
        }
    }

    const onSubmitPolicyNumber = () => {
        // Use `multiStepFormData` to access the overall multi step
        // form data object. This object may look something like this:
        // { step1: step1Data, step2: step2Data }
        beforeSubmitCleanData();
        getReCaptchaToken().then((token) => {
            (async () => {                
                setLoading(true);
                let bearerToken = isInternal === true ? await getBearToken() : '******';
                const response = await unAuthenticateQuery({ bearerToken: bearerToken, query: getPolicy,  policyDetailInput: { 
                    "brand": currentBrand,
                    "policyNumber": formData.policyNumber.trim(),
                    "dateOfBirth": isNaN(formData.dOB) === true ? helpers.getDateInMMddYYYY(formData.dOB) : '',
                    "isInternal": isInternal, 
                    "reCaptchaToken": token
                }});

                if (response.error) {
                    setLoading(false); 
                    setResponseError(response.error);
                    isInternal ? setRequestFailed(true) : setPolicyNotFound(true);
                }
                else {
                    setLoading(false);
                    if(response.data?.policyDetails?.lockoutStatus === PolicyStatus.AUTHLOCKED){
                        setPolicyStatus(PolicyStatus.AUTHLOCKED);
                        return;
                    }
                    
                    if (response.data.policyDetails && response.data.policyDetails.isValidRecaptcha && response.data.policyDetails.policyDetails && response.data.policyDetails.policyDetails.length) {                    
                        if (isInternal && response.data.policyDetails.isDuplicate) {
                            setShowDob(true);
                            setIsDuplicatePolicy(true);
                            setPolicyNotFound(true);
                        }
                        else {                
                            let policyDetails = response.data.policyDetails.policyDetails[0];
                            if(!isInternal && !policyDetails.isOverdue) {
                                formData.isOverdue = policyDetails.isOverdue;
                                formData.isValidOverdue = policyDetails.isValidOverdue;
                                setPolicyNotFound(true);
                            } else {
                                helpers.SetAuthCookies(response.data.policyDetails.authToken);
                                setOverDueAmount(policyDetails);
                                clearNextComponentData();
                                onNextStep();
                            }
                        }
                    } else {
                        setShowDob(false);
                        setIsDuplicatePolicy(false);
                        setPolicyNotFound(true);
                    }
                    return false;
                }
            })();
        });
        return false;
    };

    const clearNextComponentData = () => {
        if(multiStepFormData.ConfirmPaymentDetails) {
            multiStepFormData.ConfirmPaymentDetails.selectedAmount = '';
            multiStepFormData.ConfirmPaymentDetails.customAmount ='';
        }
    }

    const setOverDueAmount = (policyDetails) => {
        formData.amount = policyDetails.paymentAmount;
        formData.isOverdue = policyDetails.isOverdue;
        formData.overdueAmount = policyDetails.overdueAmount;
        formData.isValidOverdue = policyDetails.isValidOverdue;
        //set merchatid and policyNo to pass to Bpoint service call
        formData.merchantId = policyDetails.merchantId;
        formData.policyNo = policyDetails.policyNo;
        formData.brandCode = policyDetails.brandCode;
        formData.brandName = policyDetails.brandName;
        formData.sourceSystemCode = policyDetails.sourceSystemCode;
        formData.businessArea = policyDetails.businessArea;
    };

    const getCaptionAfterPolicyNotFoundForExternalUser = () => {
       return (formData.isValidOverdue && !formData.isOverdue) ? "You have no overdue premium payments" :  displayText.policyNotFound  ??
        `Sorry, we couldn't process your request. Please try again or call ${displayText.phoneNumber}${displayText.contactTimeFormat ? displayText.contactTimeFormat : displayText.contactTime ? " (" + displayText.contactTime + ")"  :  " (Mon-Fri 9am-5pm AET)"}`;
    }

    const getCaptionAfterPolicyNotFound = () => {
        let captionText = isDuplicatePolicy ? "Duplicate policy number, date of birth required" : isInternal ? "Please enter the details for the TAL policy number" : getCaptionAfterPolicyNotFoundForExternalUser();
        return (<GelCaption condensed style={{ color: isDuplicatePolicy ? getGelTokens().global.themeColorTextWarning : getGelTokens().global.themeColorTextDanger, marginTop: 8, }}>{captionText}</GelCaption>); 
    }

    const getCaptionAfterPolicyLockedOut = () => {
        if(policyStatus !== PolicyStatus.AUTHLOCKED)
            return null;
        const captionText = "Too many requests made. For security reasons, this page may have been locked. Please try again in 15 minutes";
        return (<GelCaption condensed style={{ color: isDuplicatePolicy ? getGelTokens().global.themeColorTextWarning : getGelTokens().global.themeColorTextDanger, marginTop: 8, }}>{captionText}</GelCaption>); 
    }

    const getCaptionAfterError = () => {
        let captionText;
        if (responseError == "Error: 400")
            captionText = "Unfortunately, payments can’t be made for this policy. Please check the policy details.";
        else
            captionText = "This service is currently unavailable. Please try again later. If the problem persists, please contact TAL IT support."
        return (<GelCaption condensed style={{ color: isDuplicatePolicy ? getGelTokens().global.themeColorTextWarning : getGelTokens().global.themeColorTextDanger, marginTop: 8, }}>{captionText}</GelCaption>);
    }

    const onInputFieldChange = (event) => {
        onFormDataChange(event);
        setPolicyNotFound(false);
    }

    return (<CustomContainer
                width={width}
                mt={getGelTokens().global.sizeBaseX6}
                mb={getGelTokens().global.sizeBaseX6}
                ml='auto'
                mr='auto'
                padding={`${getGelTokens().global.sizeBaseX10}px ${getGelTokens().global.sizeBaseX8}px`}
                borderRadius={2}
                backgroundColor={getGelTokens().global.themeColorBackgroundDefault}>
                {loading && <GelSpinner large overlay></GelSpinner>}
                <GelHeading6 as="h2">
                    How to pay
                </GelHeading6>
                <GelParagraph style={{
                    marginBottom: getGelTokens().global.sizeBaseX8
                }}>
                    Make one-off payments using VISA or Mastercard{(!isInternal && displayText.hasSuperPolicy) && " (not available for policies held in superannuation)"}.
                </GelParagraph>
                <GelForm labelAtTop onSubmit={onSubmitPolicyNumber} fieldGutter={getGelTokens().global.sizeBaseX8}>
                    <GelFormField label="Please enter your policy number">
                        <GelParagraph style={{
                            marginBottom: getGelTokens().global.sizeBaseX4
                        }}>
                            You can find this on your Anniversary Notice or your policy document.
                        </GelParagraph>
                        <GelTextInput autoFocus 
                            name="policyNumber"
                            value={formData.policyNumber}
                            onChange={onInputFieldChange}
                            required
                            regex={/^\s*[a-zA-Z0-9]*\s*$/i}
                            errorMsg={{
                                required: "Please enter a policy number",
                                regex: "Please enter a valid policy number",
                            }}
                            placeholder={"Enter your policy number here"}
                        ></GelTextInput>
                        {policyNotFound && (getCaptionAfterPolicyNotFound())}
                        {getCaptionAfterPolicyLockedOut()}
                        {requestFailed && (getCaptionAfterError())}
                        </GelFormField>
                    {(showDob || !isInternal) && (getDateOfBirthFormField(formData, onInputFieldChange))}
                    <GelFormField>
                        <GelButton name="Continue" primary medium submit stretch>
                            Continue
                        </GelButton>
                    </GelFormField>
                </GelForm>
            </CustomContainer>);
    });

    const getDateOfBirthFormField = (formData, onInputFieldChange) => {
        return (<GelFormField label="Enter date of birth of someone insured on policy">
        <GelDateInput
            name="dOB" // Makes it a managed component
            value={formData.dOB}
            onChange={onInputFieldChange}
            required
            lte={helpers.getTodayDate()}
            errorMsg={{
                required: 'Please enter a date of birth',
                format: 'Please enter valid date of birth',
                compare: 'Date of birth must be less than today'
            }}
        />
    </GelFormField>)
    };

    const WrapperComponent = ({ width, isAdobeScriptLoaded }) => {
        return (<ReCaptchaV3Provider reCaptchaKey={helpers.IsInternalUrl() ? envConfig.RECAPTCHA_INTERNAL_SITEKEY : envConfig.RECAPTCHA_SITEKEY}>
            <PolicyNumber width={width} isAdobeScriptLoaded={isAdobeScriptLoaded}></PolicyNumber>
        </ReCaptchaV3Provider>)
    }

export default WrapperComponent;