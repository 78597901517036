import { getGelTokens } from '@tal-gel/theming';

export const tableRowStyle = {
  borderBottom: `1px solid ${getGelTokens().global.themeColorBorderDefault}`,
  paddingTop: getGelTokens().global.sizeBaseX4,
  paddingBottom: getGelTokens().global.sizeBaseX4
};

export const greyBackgroundStyle = { 
  backgroundColor: getGelTokens().global.themeColorBackgroundLight
};

export const whiteBackgroundStyle = { 
  backgroundColor: getGelTokens().global.themeColorBackgroundDefault
};

export const h3FontSizeStyle = {
  fontSize: getGelTokens().global.fontSizeBodyXLarge
};

export const h4FontSizeStyle = {
  fontSize: getGelTokens().global.fontSizeBodyLarge
};

export const borderStyle = {
  borderBottom: `1px solid ${getGelTokens().global.themeColorBorderDefault}`,
  paddingTop: getGelTokens().global.sizeBaseX4,
  paddingBottom: getGelTokens().global.sizeBaseX4
};

export const pageContentStyle = {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

export const headingStyle = {
  textAlign:"left",
  marginBottom:32
};

export const boldStyle = {
  "fontWeight": "Bold"
};