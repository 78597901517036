import { TextConfig } from "./TextConfig";

export class GIOText extends TextConfig {
    constructor() {
        super()
        this.brand = 'GIO Life';
        this.title = 'GIO'
        this.phoneNumber = "1800 604 946";        
        this.privacyPolicy = 'https://www.gio.com.au/privacy.html';
        this.terms = 'GIO Life Limited';
        this.pleaseNote = '1800 604 946, 8am to 6pm AEST Monday to Friday';
        this.footer = 'TAL Life Limited ABN 70 050 109 450';
        this.hasSuperPolicy = false;
        this.aboutUs = 'https://www.gio.com.au/faqs/personal-and-life-insurance.html';
        this.security = 'https://www.gio.com.au/privacy/cookie-and-data-policy.html';
        this.complaints = 'mailto: customerservice@giolifeinsurance.com.au';
        this.disclaimer = 'https://www.gio.com.au/faqs/personal-and-life-insurance.html';
        this.contactTime = 'Mon-Fri 8am-7pm AET';
        this.contactTimeFormat = ', 8am to 6pm AEST Monday to Friday';
        this.disclaimerBrand = 'GIO Life Limited';
    };
}