import { TextConfig } from "./TextConfig";

export class AsteronText extends TextConfig {
    constructor() {
        super()
        this.brand = 'Asteron Life';
        this.title ='ASTERON';
        this.phoneNumber = "1800 221 727";
        this.privacyPolicy = 'https://www.asteronlife.com.au/privacy';
        this.terms = 'TAL Life Limited';
        this.pleaseNote = '1800 221 727 (Mon-Fri 9am-5pm AET)';
        this.footer = 'Asteron';
        this.hasSuperPolicy = true;
        this.aboutUs = 'https://www.asteronlife.com.au/about-us';
        this.security = 'https://www.asteronlife.com.au/security';
        this.complaints = 'https://www.asteronlife.com.au/about-us/complaint-management-process';
        this.disclaimer = 'https://www.asteronlife.com.au/terms-and-conditions';
    };
}