import React, { useContext, useEffect, useState } from "react";
import {
    GelBoxLayout, GelButton, GelCheckbox,
    GelCreditCardNumberInput, GelCvvInput,
    GelDateInput, GelForm, GelFormField,
    GelHeading6, GelIcon, GelLabel, GelLink,
    GelParagraph, GelRowLayout, GelTextInput,
    useGelFormData, GelCaption, GelSpinner, GelScreenDetectorContext
} from "@tal-gel/components";

import { getGelTokens } from "@tal-gel/theming";
import useScript from '../Shared/useScript';
import CustomContainer from '../Shared/CustomContainer'
import envConfig from "../../env.config";
import { ThemeContext } from "@emotion/react";
import helpers from '../Shared/helpers';
import sendAdobeTag from '../../services/sendAdobeTag';

const PaymentDetails = React.memo(({ width, BPOINT }) => {
    const {
        formData, // form data of this step
        onFormDataChange,
        onNextStep,
        onPrevStep, // goes back to the previous step
        multiStepFormData, // overall form data of all steps
        onFormFieldChange,
    } = useGelFormData(
        {
            // initial data of this step
            nameOnCardField: "",
            cardNumberField: "",
            expiryDate: "",
            cvv: "",
            terms: "",
        },
        // Step name, note that `formData` is to be scoped
        // to this name in the overall multi step form data
        // object, Eg: { step2: formData }
        "PaymentDetails"
    );
    const [paymentErrorData, setErrorData] = useState();
    const [status, setAttachPaymentMethodStatus] = useState();
    const [authkey, setauthkey] = useState();
    const [invalidCardNo, setInvalidCardNo] = useState();
    const [invalidExpiryDate, setInvalidExpiryDate] = useState();
    const [invalidCVV, setInvalidCVV] = useState();
    const [loading, setLoading] = useState();
    const currentBrand = helpers.GetCurrentBrand();
    const [displayText] = useState(helpers.SetCurrentBrandText(currentBrand, multiStepFormData.policyNumber.businessArea, multiStepFormData.policyNumber.sourceSystemCode));
    const [isInternal, setIsInternal] = useState(helpers.IsInternalUrl());

    
    // Verify If auth key is available otherwise navigate to error page
    useEffect(() => {
        sendAdobeTag({ brand:multiStepFormData.policyNumber.brandCode, pageEventName:"Form Step", formStep:"Payment Details"});

        document.body.scrollTop = 0;
        if (multiStepFormData.ConfirmPaymentDetails.authKey) {
            setauthkey(multiStepFormData.ConfirmPaymentDetails.authKey);
        } else {
            //Navigate to error page when api call is unsuccessfull
            onNextStep();
        }
    }, []);

    width = helpers.GetComponentScreenWidth();

    //onform submit event call Bpoint to steup payment method. 
    // When it is success call process transaction finish the payment and display receipt to the user
    const onSubmit = () => {

        const date_array = formData.expiryDate.split('-');
        const month = date_array[1];
        const year = date_array[0].slice(-2);
        setLoading(true);
        try {
            //call to attach payment method details with authkey
            window.BPOINT.txn.authkey.attachPaymentMethod(
                authkey,
                {
                    "card": {
                        "cvn": formData.cvv,
                        "number": formData.cardNumberField,
                        "expiry": {
                            "month": month,
                            "year": year
                        },
                        "name": formData.nameOnCardField
                    }
                },
                function attachPaymentMethod(code, data) {
                    setLoading(false);
                    if (code === "success") {
                        //set status in form data - to access this status in other page
                        onFormFieldChange({
                            add: {
                                attachPaymentMethodStatus: code,
                                attachPaymentMethodData: data,
                            },
                        });
                        onNextStep();
                    } else {
                        // show an error to the user based on error data
                        handleInvalidFields(code, data)
                    }
                }
            );
        }
        catch (error) {
            onNextStep();
        }
    }

    // Handle invalid card details
    const handleInvalidFields = (code, data) => {
        if (data.details) {
            if (data.details[0].field === 'authkey') {
                onFormFieldChange({
                    add: {
                        attachPaymentMethodStatus: code,
                        attachPaymentMethodData: data,
                    },
                });
                onNextStep();
            } else {
                //handle error for inavlid fields
                data.details[0].field === 'card.number' ? setInvalidCardNo(true) : setInvalidCardNo(false);
                data.details[0].field === 'card.expiry' ? setInvalidExpiryDate(true) : setInvalidExpiryDate(false);
                data.details[0].field === 'card.cvn' ? setInvalidCVV(true) : setInvalidCVV(false);
            }
        }
    };

    const onPrivacyPolicyClick = (privacyPolicy) => {
        window.open(privacyPolicy, "_blank");
    };


    const onInputFieldCardNoChange = (event) => {     
        onFormDataChange(event);
        setInvalidCardNo(false);
    }

    const onInputFieldExpiryDateChange = (event) => {     
        onFormDataChange(event);
        setInvalidExpiryDate(false);
    }

    const onInputFieldCVV = (event) => {     
        onFormDataChange(event);
        setInvalidCVV(false);
    }

    const getMinimumExpiryDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-indexed
        const formattedDate = `${year}-${month}`;
        return formattedDate;
    }

    return (<>
        {authkey && <>
            <CustomContainer
                width={width}
                mt={getGelTokens().global.sizeBaseX6}                
                ml='auto'
                mr='auto'
                padding={`${getGelTokens().global.sizeBaseX5}px ${getGelTokens().global.sizeBaseX8}px`}
            >
                {loading && <GelSpinner large overlay></GelSpinner>}
                <GelRowLayout gutter="medium">
                    <GelBoxLayout distribution="spaceBetween" space={'auto'}>
                        <GelParagraph>Policy number</GelParagraph>
                        <GelLabel>{multiStepFormData.policyNumber.policyNo}</GelLabel>
                    </GelBoxLayout>
                    <GelBoxLayout style ={{ "visibility" : isInternal ? "visible" : "hidden"}}  disabled={false} distribution="spaceBetween" space={'auto'}>
                        <GelParagraph>Brand</GelParagraph>
                        <GelLabel>{multiStepFormData.policyNumber.brandName}</GelLabel>
                    </GelBoxLayout>
                    <GelBoxLayout distribution="spaceBetween" space={'auto'}>
                        <GelParagraph>Amount</GelParagraph>
                        <GelLabel>
                            ${multiStepFormData.ConfirmPaymentDetails.selectedAmount}
                        </GelLabel>
                    </GelBoxLayout>
                </GelRowLayout>
            </CustomContainer>
            <CustomContainer
                width={width}
                mt={getGelTokens().global.sizeBaseX6}
                mb={getGelTokens().global.sizeBaseX6}
                ml='auto'
                mr='auto'
                padding={getGelTokens().global.sizeBaseX8}
                borderRadius={getGelTokens().global.sizeBaseHalf}
                backgroundColor={getGelTokens().global.themeColorBackgroundDefault}
            >
                <GelBoxLayout
                    distribution="spaceBetween"
                    alignment="start"
                    space="auto"
                    style={{ marginBottom: getGelTokens().global.sizeBaseX8 }}
                >
                    <GelHeading6 textAlign="left" as="h2">
                        Payment details
                    </GelHeading6>
                    <>
                        <GelIcon inline name="Visa" />
                        <GelIcon inline name="Mastercard" />
                    </>
                </GelBoxLayout>

                <GelForm
                    labelAtTop
                    id="paymentMethodForm"
                    disableOnSubmit
                    onSubmit={onSubmit}
                    parseResponseBody={false} // Remove this if you use `fetch` in `onSubmit`
                    fieldGutter={getGelTokens().global.sizeBaseX8}
                >
                    <GelFormField label="Name on card">
                        <GelTextInput
                            name="nameOnCardField"
                            id="nameOnCardField"
                            onChange={onFormDataChange}></GelTextInput>
                    </GelFormField>
                    <GelFormField label="Card Number">
                        <GelCreditCardNumberInput
                            name="cardNumberField"
                            id="cardNumberField"
                            value={formData.cardNumberField}
                            onChange={onInputFieldCardNoChange}
                            required
                            errorMsg={{
                                required: "Card number is required",
                                format: "Invalid card number",
                            }}
                        />
                        {invalidCardNo && (<GelCaption condensed style={{ color: getGelTokens().global.themeColorTextDanger, marginTop: 8, }}>Invalid card number</GelCaption>)}
                    </GelFormField>
                    <GelBoxLayout
                        distribution="spaceBetween"
                        alignment="start"
                        style={{ marginBottom: getGelTokens().global.sizeBaseX8 }}
                    >
                        <GelFormField label="Expiry Date">
                            <GelDateInput
                                name="expiryDate"
                                id="expiryMonthField"
                                value={formData.expiryDate}
                                style={{   marginTop: displayText.expiryDateMarginTop  > 0 ? displayText.expiryDateMarginTop : 0 }}
                                onChange={onInputFieldExpiryDateChange}
                                monthYear
                                required
                                gte={getMinimumExpiryDate()}
                                errorMsg={{
                                    required: "Expiry date is required",
                                    compare: "Expiry date invalid",
                                    format: "Invalid expiry date format",
                                }}/>
                            {invalidExpiryDate && (<GelCaption condensed style={{ color: getGelTokens().global.themeColorTextDanger, marginTop: 8, }}>Expiry date invalid</GelCaption>)}
                        </GelFormField>
                        <GelFormField
                            label="CVV"
                            tooltip="A 3 or 4 digit code that is usually printed on the back of your card">
                            <GelCvvInput
                                name="cvv"
                                id="cvnField"
                                value={formData.cvv}
                                onChange={onInputFieldCVV}
                                required
                                errorMsg={{
                                    required: "CVV is required",
                                    length: "CVV must be either 3 or 4 digits",
                                }}
                            />
                            {invalidCVV && (<GelCaption condensed style={{ color: getGelTokens().global.themeColorTextDanger, marginTop: 8, }}>Invalid CVV</GelCaption>)}
                        </GelFormField>
                    </GelBoxLayout>
                    <GelFormField
                        style={{
                            backgroundColor: getGelTokens().global.themeColorBackgroundLight,
                            padding: 16,
                        }}
                    >
                    <GelCheckbox name="terms" required>
                        <div>I am the cardholder and I authorise {displayText.disclaimerBrand ?? "TAL Life Limited"} to debit the amount shown above from this credit card. 
                        I understand that this debit will appear on my card statement as {displayText.terms}, and I consent to the collection and use of my personal 
                        information in accordance with the {displayText.parentBrand ? 
                        [<GelLink  onClick={() => onPrivacyPolicyClick(displayText.parentBranchPrivacyPolicy)}>{displayText.parentBrand} Privacy Policy</GelLink>, " and "]: ""}
                        <GelLink  onClick={() => onPrivacyPolicyClick(displayText.privacyPolicy)}>{displayText.brand} Privacy Policy</GelLink>.
                        </div>    
                    </GelCheckbox>
                    </GelFormField>
                    <GelBoxLayout
                        distribution="spaceBetween"
                        space={"0 auto"}
                        style={{ marginTop: getGelTokens().global.sizeBaseX8 }}
                    >
                        <GelFormField>
                            <GelButton
                                name="" // Makes it a managed component
                                secondary
                                medium
                                // Goes back to the previous step
                                onClick={onPrevStep}
                            >
                                Back
                            </GelButton>
                        </GelFormField>

                        <GelFormField>
                            <GelButton
                                name="submitButton" // Makes it a managed component
                                id="submitButton"
                                primary
                                medium
                                submit
                                iconLeft="LockClosed"
                            >
                                Pay ${multiStepFormData.ConfirmPaymentDetails.selectedAmount}
                            </GelButton>
                        </GelFormField>
                    </GelBoxLayout>
                </GelForm>
            </CustomContainer>
        </>}
    </>
    );
});

export default PaymentDetails;