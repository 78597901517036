import { TextConfig } from "./TextConfig";

export class NIBText extends TextConfig {
    constructor() {
        super()
        this.brand = 'nib';
        this.title = 'NIB';
        this.parentBrand ='TAL';
        this.phoneNumber = "1800 633 170";
        this.privacyPolicy = 'https://www.nib.com.au/legal/privacy-policy';
        this.parentBranchPrivacyPolicy='https://www.tal.com.au/privacy-policy';
        this.terms = 'nib life pty ltd';
        this.pleaseNote = '1800 633 170, 8am - 7pm Eastern Standard Time Monday to Friday';
        this.footer = 'nib life pty ltd ABN 91 003 037 625';
        this.hasSuperPolicy = true;
        this.aboutUs = 'https://www.nib.com.au/life-insurance/';
        this.security = 'https://www.nib.com.au/health-services/online-terms';
        this.complaints = 'mailto: niblife@talalliances.com.au';
        this.disclaimer = 'https://www.nib.com.au/life-insurance/';
        this.contactTime = '8am - 7pm Eastern Standard Time Monday to Friday';
        this.disclaimerBrand = 'nib life pty ltd';
        this.contactTimeFormat = ', 8am - 7pm Eastern Standard Time Monday to Friday';
    };
}