import { TextConfig } from "./TextConfig";

export class NRMAText extends TextConfig {
    constructor() {
        super()
        this.brand = 'NRMA';
        this.title = 'NRMA';
        this.parentBrand ='TAL';
        this.phoneNumber = '1300 996 143';
        this.privacyPolicy = 'https://www.nrma.com.au/privacy-security';
        this.parentBranchPrivacyPolicy='https://www.tal.com.au/privacy-policy';
        this.terms = 'TAL Direct Pty Limited';
        this.pleaseNote = '1300 996 143 (8am - 7pm Eastern Standard Time Monday to Friday)';
        this.footer = 'TAL Life Limited ABN 70 050 109 450';
        this.hasSuperPolicy = false;
        this.aboutUs = 'https://www.tal.com.au/nrma';
        this.security = 'https://www.nrma.com.au/privacy-security';
        this.complaints = 'https://www.tal.com.au/contact-us/complaint-handling-process';
        this.disclaimer = 'https://www.nrma.com.au/claims/life-insurance';
        this.contactTime = '8am - 7pm Eastern Standard Time Monday to Friday';
        this.disclaimerBrand = 'TAL Direct Pty Limited';
    };
}